// ** @jsx jsx *
import React, { useState, useEffect } from "react"
import Product from "./Product"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeGrid } from "react-window"
import { jsx } from "theme-ui"
import { useStaticQuery, graphql, Link } from "gatsby"
const ProductList = ({
  products,
  money,
  scrollProductList,
  heightProductList = "calc(100vh - 180px)",
  prevPage,
}) => {
  const [columns, setColumns] = useState(2)
  const { sanityUserInterface } = useStaticQuery(graphql`
    query modalActive {
      sanityUserInterface {
        cartOptions {
          activeModal
        }
      }
    }
  `)
  let validateModal = false

  try {
    validateModal =
      (sanityUserInterface &&
        sanityUserInterface.cartOptions &&
        sanityUserInterface.cartOptions.activeModal) ||
      false
  } catch (error) {}
  useEffect(() => {
    const breakpoint500 =
      typeof window !== "undefined" &&
      window.matchMedia("(min-width: 500px)").matches
    const breakpoint900 =
      typeof window !== "undefined" &&
      window.matchMedia("(min-width: 900px)").matches
    if (breakpoint500) {
      setColumns(3)
    }
    if (breakpoint900) {
      setColumns(4)
    }
  }, [])
  const RenderProduct = ({ columnIndex, rowIndex, style }) => {
    let product

    if (rowIndex * columns + columnIndex < products.length) {
      product = products[rowIndex * columns + columnIndex]
    } else {
      return <></>
    }
    return (
      <div
        style={style}
        key={product.id}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Product
          modal={validateModal}
          product={product}
          money={money}
          card
          prevPage={prevPage}
        />
      </div>
    )
  }

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 3,
      }}
    >
      <div>
        <AutoSizer
          style={{
            width: "100vw",
            maxWidth: `${250 * columns}px`,
            height: heightProductList,
          }}
        >
          {({ height, width }) => (
            <FixedSizeGrid
              columnCount={columns}
              columnWidth={Math.round(width / columns)}
              height={height}
              width={width}
              rowCount={Math.ceil(products.length / columns)}
              rowHeight={290}
              ref={scrollProductList}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowX: "hidden !important",
              }}
            >
              {RenderProduct}
            </FixedSizeGrid>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

export default ProductList
